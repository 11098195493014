import React, { useState }  from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import CategoryRename from "~/components/category-rename"

const getCategoryBySlug = (categories, slug) => {
  let foundCategory = null
  for (let i=0; i<categories.length; i++) {
    if (categories[i].node.slug == slug) {
      foundCategory = {
        name: categories[i].node.name,
        productCount: categories[i].node.products && categories[i].node.products.length || 0
      }
    }
  }
  return foundCategory
}

const CategoryList = ({ slug, showMenu }) => {
  const [menuShow, setMenuShow] = useState(0);

  return (
    <StaticQuery
      query={graphql`
        query AllCategoryQuery {
          allStrapiCategory {
            edges {
              node {
                name
                id
                slug
                products {
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => {
        const categories = data.allStrapiCategory.edges
        const selectedCategory = getCategoryBySlug(categories, slug)
        // s1ohh0dz is '全部', category '全部' is deprecated
        return (
          <div className="flex-col ">
            <div className={((showMenu) ? ' hidden ' : '') + " sm:hidden inline-block cursor-pointer"} onClick={() => setMenuShow(!menuShow)}>
              <div className={"btn-category-pulldown"} >
                {selectedCategory ? CategoryRename(selectedCategory.name) : "选择类别"} <span className={((slug == "s1ohh0dz") ? ' hidden ' : (selectedCategory ? "px-1 text-xs text-gray-100 rounded bg-gray-400" : "hidden") )}>{(slug == "s1ohh0dz") ? '' : (selectedCategory && selectedCategory.productCount)}</span>
              </div>
              <svg className={" inline-block w-4 h-4 position t-0 m-0 transition ease-in-out duration-300 transform-gpu " + ((menuShow || showMenu) ? ' -rotate-180 ' : ' rotate-0 ')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>

            <div className={"transition ease-in-out duration-100 transform-gpu " + ((menuShow || showMenu) ? ' h-auto opacity-100 scale-100 translate-y-2 ' : ' h-0 opacity-0 scale-y-60 -translate-y-2 ') + " sm:h-auto sm:opacity-100 sm:scale-100 sm:translate-y-0 "}>
            {categories.map(({ node }) => {
              return (
                <Link key={node.slug} className={"btn-category"
                                + ((menuShow || showMenu) ? ' inline-block ' : ' hidden sm:inline-block ')  + ((slug == node.slug) ? ' btn-category-selected ' : ' btn-category-nonselected ')}
                                to={`/categories/${(node.slug == "s1ohh0dz") ? '' : node.slug + '/'}`}>
                  {CategoryRename(node.name)} <span className={((node.slug == "s1ohh0dz") ? ' hidden ' : ' btn-category-product-count' )}>{(node.slug == "s1ohh0dz") ? '' : (node.products && node.products.length)}</span>
                </Link>
              )
            })}
            </div>
          </div>
        )}
      }
    />
  )
}

CategoryList.propTypes = {
  slug: PropTypes.string,
  hideMneu: PropTypes.bool,
}

CategoryList.defaultProps = {
  slug: null,
  showMenu: false,
}

export default CategoryList
