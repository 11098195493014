import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"

import Layout from "~/components/layout"
import CategoryList from "~/components/category-list"
import RelatedProduct from "~/components/related-product"
import SEO from "~/components/seo"
import SharpImage from "~/components/sharp-image"
import SharpImageFixed from "~/components/sharp-image-fixed"
import ProductRename from "~/components/product-rename"
import ProductLdJson from "~/components/product-ld-json"
import ProductMetaTag from "~/components/product-meta-tag"
import ProductImageDesc from  "~/components/product-image-desc"
import { formatPrice } from "~/helpers/currency-formatter"
import GoogleAdsense from "~/components/google-adsense"

const ProductPage = ({ data }) => {
  const product = data.strapiProduct

  const seo = {
    title: product.name,
    image: product.ImageDesc[0].image && product.ImageDesc[0].image.publicURL || null,
    description: product.ImageDesc && ProductImageDesc(product.ImageDesc) || product.name,
    ldJson: ProductLdJson(product),
    metaTags: ProductMetaTag(product)
  }

  const showQrcode = (qrcode) => {
    return (
      <div className="inline-block p-2 border-t-4 border-green-600 w-auto items-center justify-center shadow">
        <div className="flex">
          <svg className="animate-bounce" xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="-7.583 -10.291 65.718 61.744"><defs><linearGradient gradientUnits="userSpaceOnUse" gradientTransform="scale(1.06228 .94137)" id="a" y2=".15" x2="17.242" y1="32.431" x1="17.242"><stop offset="0%" stopColor="#78D431"/><stop offset="100%" stopColor="#9EEE69"/><stop offset="100%" stopColor="#9EEE69"/></linearGradient><linearGradient gradientUnits="userSpaceOnUse" gradientTransform="scale(1.05667 .94637)" id="b" y2="14.697" x2="33.473" y1="41.634" x1="33.473"><stop offset="0%" stopColor="#E4E6E6"/><stop offset="100%" stopColor="#F5F5FF"/></linearGradient></defs><g fill="none"><path fill="url(#a)" d="M0 15.306c0 4.592 2.485 8.787 6.324 11.565.34.227.509.567.509 1.02 0 .114-.057.284-.057.397-.282 1.134-.79 3.005-.847 3.062-.056.17-.113.283-.113.453 0 .34.283.624.621.624.113 0 .226-.057.34-.114l4.008-2.324c.283-.17.622-.283.96-.283.17 0 .396 0 .565.056 1.864.567 3.896.85 5.986.85 10.108 0 18.296-6.859 18.296-15.306S28.403 0 18.295 0C8.188 0 0 6.86 0 15.306"/><path fill="url(#b)" d="M35.342 39.62c1.747 0 3.437-.228 4.958-.685.112-.057.281-.057.45-.057.282 0 .564.114.789.228l3.324 1.942c.112.057.169.115.281.115a.508.508 0 00.507-.514c0-.115-.056-.229-.056-.4 0-.057-.45-1.6-.676-2.57-.056-.115-.056-.229-.056-.343 0-.343.169-.628.45-.857 3.211-2.342 5.24-5.826 5.24-9.71 0-7.082-6.817-12.85-15.21-12.85s-15.21 5.71-15.21 12.85c0 7.083 6.816 12.851 15.21 12.851z"/><path fill="#187E28" d="M14.548 10.365a2.343 2.343 0 01-2.35 2.369 2.343 2.343 0 01-2.352-2.37 2.343 2.343 0 012.351-2.368 2.343 2.343 0 012.351 2.369m12.198 0a2.343 2.343 0 01-2.352 2.369 2.343 2.343 0 01-2.35-2.37 2.343 2.343 0 012.35-2.368 2.343 2.343 0 012.352 2.369"/><path fill="#858C8C" d="M38.502 22.802a2.057 2.057 0 104.115 0 2.057 2.057 0 10-4.115 0m-10.14 0a2.057 2.057 0 104.115 0 2.057 2.057 0 10-4.115 0"/></g></svg>
          <p className="flex-end text-green-600">微信<span className="text-gray-700 font-bold">"扫一扫"</span></p>
        </div>
        <p className="-mt-3 text-red-600 text-center">了解产品 更多优惠</p>
        <SharpImageFixed className="mt-1 rounded-md" image={qrcode} alt={"微信 \"扫一扫\" 了解产品 更多优惠"} title={"微信 \"扫一扫\" 了解产品 更多优惠"} />
      </div>
    )
  }

  // const flexJustify = product.specifications.length > 0 ? "between" : "center"
  // const flexJustify = "center"

  return (
    <Layout>
      <div className="m-auto">
      <SEO seo={seo} />
      <CategoryList />

      <div className="max-w-screen-md m-auto">
        <div className="flex-row sm:flex mt-6 justify-between">
            <div className="pr-3">
              <h1 className="text-3xl">{ProductRename(product.name)}</h1>
              { product.sold && <p className="mt-3 text-sm text-gray-400">已售 <span className="font-semibold">{product.sold}</span></p> }
              <div className="mt-4 relative">
                <p className="text-yellow-50 bg-yellow-400 px-2 py-1 rounded-full inline-block text-xs font-medium">优惠价</p>
                <div aria-hidden="true" className="text-yellow-50 bg-yellow-400 px-2 py-1 rounded-full inline-block text-xs absolute left-0 font-medium animate-ping opacity-25">优惠价</div>
              </div>
              <div className="mt-1 text-base flex align-bottom">
                <p className="text-2xl font-semibold text-red-700">{product.price && formatPrice(product.price)}</p>
                <p className="ml-2 line-through text-gray-400 self-center">{product.originalPrice && formatPrice(product.originalPrice)}</p>
              </div>
            </div>
            
            <div className="my-6 inline-block w-full text-center sm:my-0 sm:w-auto">
              {showQrcode(product.qrcode)}
            </div>
        </div>

        <div className="mt-6">
          {product.ImageDesc.map((productImage, index) => {
            if (productImage.desc && productImage.desc.trim() == '[ignore content]') {
              
            } else {
              const showAds = [2,5,8,11,14,17,21,24,27,30]
              let ads = ''
              if (showAds.includes(index)) {
                return (
                  <GoogleAdsense key={`ga-${index}`} className="mt-6" style={{display: 'block', textAlign: 'center'}} layout="in-article" format="fluid" slot="7103494970" />
                )
              } else {
                return (
                  <div key={index} className="md:col-span-2 mt-6 relative overflow-hidden">
                    <p className="absolute top-0 text-gray-50">
                      {productImage.desc}
                    </p>
                    <div>
                      <SharpImage className="rounded-md shadow-lg" image={productImage.image} alt={productImage.desc} title={product.name} />
                    </div>
                  </div>
                )
              }
            }
          })}
        </div>
      </div>

      <div className="mt-12 text-center">
        {showQrcode(product.qrcode)}
      </div>
      
      <div className="my-6 mb-12 text-center">
        <ReactMarkdown
          className="prose md:w-4/5 m-auto"
          children={"微信\"扫一扫\"了解详情，得知更多产品优惠，还有更多好用产品等着您喔!"}
        />
      </div>

      <RelatedProduct productSlug={product.slug} />

      <GoogleAdsense slot="5243618393" responsive="true" />

      <div className="mb-16 mt-8">
        <CategoryList showMenu={true} />
      </div>
    </div>
    </Layout>
  )
}

export const query = graphql`query ProductQuery($slug: String!) {
  strapiProduct(slug: {eq: $slug}) {
    name
    slug
    price
    originalPrice
    sold
    qrcode {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED,
          placeholder: BLURRED,
          formats: [AUTO, WEBP]
          quality: 100
        )
      }
    }
    ImageDesc {
      desc
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED,
            placeholder: TRACED_SVG,
            formats: [AUTO, WEBP]
            quality: 75
          )
        }
      }
    }
  }
}
`

export default ProductPage
