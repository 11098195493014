import React from "react"
import ProductBrandName from "~/components/product-brand-name"
import ProductImageDesc from "~/components/product-image-desc"

const sitePublicUrl = process.env.GATSBY_PUBLIC_URL || "http://localhost:3000"

const safeString = (str) => {
  return str.replace(/\n/g, ' ').replace(/\\ /g, ' ').replace(/\\/g, '').replace(/"/g, '\\"')
}

const ProductLdJson = (product) => {
  const name = safeString(product.name)
  const desc = product.ImageDesc && ProductImageDesc(product.ImageDesc, 1600) || ''
  const images = JSON.stringify(product.ImageDesc.filter(item => {
    if (!item.image || item.desc.trim() == '[ignore content]') {
      return false
    } else {
      return true
    }
  }).map(item => {
    if (item.image) {
      // childImageSharp {
      //   fluid {
      //     srcWebp
      //   }
      // }
      // return item.image.childImageSharp.fluid.srcWebp || item.image.publicURL
      return sitePublicUrl + item.image.publicURL
    }
  }))

  const price = safeString(product.price.toString())
  const brand = safeString(ProductBrandName(product.name))

  let ldJson = `{
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "${name}",
    "image": ${images},
    "description": "${desc}",
    "brand": {
      "@type": "Brand",
      "name": "${brand}"
    },
    "offers": {
      "@type": "Offer",
      "url": "${sitePublicUrl}/products/${product.slug}",
      "priceCurrency": "CNY",
      "price": "${price}",
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock"
    }
  }`

  try {
    ldJson = JSON.stringify(JSON.parse(ldJson))
  } catch(err) {
    console.log(err)
    console.log(`BEGIN ldJson:`)
    console.log(ldJson)
    console.log(`END ldJson:`)
  }

  return (
    <script type="application/ld+json">{`${ldJson}`}</script>
  )
}

export default ProductLdJson
