import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Card from "~/components/styled/card"
import SharpImage from "~/components/sharp-image"
import ProductRename from "~/components/product-rename"
import { formatPrice } from "~/helpers/currency-formatter"
import GoogleAdsense from "~/components/google-adsense"

const ProductList = ({ products, gridCols, showAds }) => {
  if (products.length == 0) {
    return (
      <div class="flex">
        <svg className="inline-block text-gray-400 w-5 h-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
        <p className="text-gray-500 ml-2">努力抢购中...</p>
      </div>
    )
  } else {
    return (
      <>
      <div className={`grid ${gridCols} gap-6`}>
      {products.map((product, index) => {
          let ads = ''
          if (showAds && index != 0 && index % 6 == 0) {
            ads = <GoogleAdsense key={`ga-${index}`} className="col-span-1 sm:col-span-2 md:col-span-3" style={{display: 'block', textAlign: 'center'}} layout="in-article" format="fluid" slot="7103494970" />
          }
          return (
              <>
                { ads }
                <Card key={product.slug}>
                  <Link to={`/products/${product.slug}/`} key={product.slug}>
                      <SharpImage
                        className="rounded-t-md object-cover object-top h-96 md:h-80 lg:h-96 w-full border-gray-200 border-b"
                        image={product.ImageDesc[0].image}
                        alt={product.ImageDesc[0].desc} 
                        title={product.name}
                      />
                      <div className="px-4 py-4 flex-col">
                        <div>
                          <p className="text-yellow-50 bg-yellow-400 px-2 py-1 rounded-full inline-block text-xs font-medium">优惠价</p>
                          <div className="mt-2 text-base flex align-bottom">
                            <p className="text-2xl font-semibold text-red-700">{product.price && formatPrice(product.price)}</p>
                            <p className="ml-2 line-through text-gray-400 self-center">{product.originalPrice && formatPrice(product.originalPrice)}</p>
                          </div>
                        </div>
                        <h2 className="mt-2 text-lg tracking-wide text-black">{ProductRename(product.name)}</h2>
                        { product.sold && <p className="mt-2 text-sm text-gray-400">已售 <span className="font-semibold">{product.sold}</span></p> }
                      </div>
                  </Link>
                </Card>
              </>
          )
        })
      
      }
      </div>
      </>
    )
  }
}

ProductList.propTypes = {
  products: PropTypes.array,
  gridCols: PropTypes.string,
  showAds: PropTypes.bool
}

ProductList.defaultProps = {
  gridCols: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
  showAds: true
}

export default ProductList
