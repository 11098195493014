import React from "react"

const ProductRename = (name, json=false) => {
  name = name.trim()

  let tag = ''
  let tagSection = name.split('】')
  if (tagSection.length > 1) {
    let tagLeft = tagSection.shift()
    if (/^【.*/.test(tagLeft)) {
      if (json) {
        // remove '【'
        tag = `${tagLeft.substring(1)}`
      } else {
        tag = <><p className="text-base text-gray-700 tracking-widest -ml-2">{tagLeft}】</p></>
      }
    } else {
      tagSection.push(tagLeft + '】')
    }
    name = tagSection.join('】').trim()
  }

  let ship = ''
  let shipSection = name.split(' ')
  if (shipSection.length > 1) {
    let shipFrom = shipSection.pop()
    if (shipFrom.trim() == "深圳保税仓发货" || shipFrom.trim() == "香港直邮") {
      if (json) {
        ship = shipFrom.trim()
      } else {
        ship = <><br /><span className="inline-block -ml-1 mt-3 mb-1 px-2 py-1 rounded-xl text-sm bg-gradient-to-r from-green-200 to-blue-300">{shipFrom.trim()}</span></>
      }
    } else {
      shipSection.push(' ' + shipFrom)
    }
    name = shipSection.join(' ').trim()
  }

  let capacity = ''
  name = name.trim()
  let capacityExtract = name.match(/([0-9]+)/g)
  if (capacityExtract) {
    let lastNumberString = capacityExtract.pop()
    let lastIndexOfNumber = name.lastIndexOf(lastNumberString)
    let extractString = name.substring(lastIndexOfNumber)
    if (/^[0-9]+.*\/.*$/.test(extractString)) {
      if (json) {
        capacity = extractString
      } else {
        capacity = <><br /><span className="text-base text-gray-500 tracking-widest">{extractString}</span></>
      }
    }
    name = name.substring(0, lastIndexOfNumber).trim()
  }

  if (json) {
    return {
      tag,
      name,
      capacity,
      ship
    }
  } else {
    return (
      <>
        {tag} {name} {capacity} {ship}
      </>
    )
  }

}

export default ProductRename
