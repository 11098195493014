import React  from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import ProductList from "~/components/product-list"
import _ from "lodash"

const RelatedProduct = ({productSlug}) => {
  // numOfProductsDisplay / 12 
  const numOfProductsDisplay = 6
  // 新品, 送爸妈, 护发养发, 安神助眠, 两性健康
  return (
    <StaticQuery
      query={graphql`
        query relatedProductQuery {
          allStrapiProduct(
            filter: {categories: {elemMatch: {slug: {in: ["0vgnv0po", "wnhplg0f", "79dxvycd1", "0p3fwxww", "mdni3fk11"]}}}}
            sort: {fields: sold, order: DESC}
            limit: 12
          ) {
            edges {
              node {
                name
                slug
                price
                originalPrice
                sold
                qrcode {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FIXED
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                      quality: 100
                    )
                  }
                }
                ImageDesc {
                  desc
                  image {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 360
                        layout: CONSTRAINED
                        placeholder: TRACED_SVG
                        formats: [AUTO, WEBP]
                        quality: 75
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        let relatedProducts = data.allStrapiProduct.edges.map(({node}) => node)
        relatedProducts = relatedProducts.filter(item => item.slug != productSlug)
        relatedProducts = _.shuffle(relatedProducts);
        relatedProducts = relatedProducts.slice(0, numOfProductsDisplay)

        if (relatedProducts && relatedProducts.length > 0 ) {
          return (
            <div className="flex flex-col my-6 mb-6">
              <h2 className="text-3xl font-bold text-center">推荐产品</h2>
              <hr className="mt-6 mb-12 m-auto w-24 border-t-4" />
              <ProductList
                products={relatedProducts}
                gridCols="grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
                showAds={false}
              />
            </div>
          )
        } else {
          return ''
        }
      }
    }/>
  )
}

RelatedProduct.propTypes = {
  productSlug: PropTypes.string,
}

RelatedProduct.defaultProps = {
  productSlug: null,
}

export default RelatedProduct
