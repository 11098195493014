import React from "react"
import PropTypes from "prop-types"
class GoogleAdsense extends React.Component {
  componentDidMount() {
    if (window) (window.adsbygoogle = window.adsbygoogle || []).push({})
  }

  render() {
    return (
      <ins className={`${this.props.className} adsbygoogle`}
        style={this.props.style}
        data-ad-layout={this.props.layout}
        data-ad-format={this.props.format}
        data-ad-client="ca-pub-1773907966215450"
        data-ad-slot={this.props.slot}
        data-full-width-responsive={this.props.responsive}></ins>
    )
  }
}

GoogleAdsense.propTypes = {
  className: PropTypes.string,
  slot: PropTypes.string.isRequired,
  style: PropTypes.object, // eslint-disable-line
  layout: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string
}

GoogleAdsense.defaultProps = {
  className: '',
  style: {display: 'block'},
  format: 'auto',
  layout: '',
  responsive: 'false'
}

export default GoogleAdsense
