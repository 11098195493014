const safeString = (str) => {
  return str.replace(/\n/g, ' ').replace(/\\ /g, ' ').replace(/\\/g, '').replace(/"/g, '\\"')
}

const ProductImageDesc = (imageDesc, maxLength=160) => {
  if (!imageDesc) {
    return ''
  }
  
  let desc = ''
  for (let i=0; i<imageDesc.length; i++) {
    if (!imageDesc[i].desc) {
      continue
    }
    if (desc != '') {
      desc += ', '
    }
    desc += safeString(imageDesc[i].desc)
    if (desc.length >= maxLength) {
      break
    }
  }
  desc = desc.trim()
  // if (desc.length > maxLength) {
  //   desc = desc.substring(0, maxLength).trim() + '...'
  // }
  return desc
}

export default ProductImageDesc
