const ProductBrandName = (name) => {
  let nameMatch = name.match(/】*([\x00-\x7F]{2,})/g)
  if (nameMatch && nameMatch[0]) {
    let foundIndexOf = nameMatch[0].indexOf('】')
    if (foundIndexOf < 0) {
      return nameMatch[0].trim()
    } else {
      return nameMatch[0].substring(foundIndexOf+1).trim()
    }
  }
  return name
}

export default ProductBrandName
