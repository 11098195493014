import ProductBrandName from "~/components/product-brand-name"

const sitePublicUrl = process.env.GATSBY_PUBLIC_URL || "http://localhost:3000"

const safeString = (str) => {
  return str.replace(/\n/g, ' ').replace(/\\ /g, ' ').replace(/\\/g, '').replace(/"/g, '\\"')
}

const ProductMetaTag = (product) => {
  const tags = [
    {
      property: "og:url",
      content: `${sitePublicUrl}/products/${product.slug}`,
    },
    {
      property: "og:type",
      content: "product",
    },
    {
      property: "product:price:currency",
      content: "CNY",
    },
    {
      property: "product:price:amount",
      content: safeString(product.price.toString()),
    },
    {
      property: "product:availability",
      content: "in stock",
    },
    {
      property: "product:condition",
      content: "new",
    },
    {
      property: "product:brand",
      content: safeString(ProductBrandName(product.name)),
    }
  ]

  return tags
}

export default ProductMetaTag
