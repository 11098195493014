import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types"

const SharpImageFixed = ({ image, className, alt, title }) => {
  const data = useStaticQuery(graphql`{
  strapiGlobal {
    placeHolder {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
  }
}
`)

  if (!image) {
    return (
      <GatsbyImage
        image={data.strapiGlobal.placeHolder.childImageSharp.gatsbyImageData}
        className={className} />
    );
  }

  return (
    <GatsbyImage
      image={image.childImageSharp.gatsbyImageData}
      className={className}
      alt={alt}
      title={title} />
      // imgStyle={{  objectPosition: "top" }} />
      // objectFit: "contain"
  );
}

SharpImageFixed.propTypes = {
  image: PropTypes.object,
  className: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
}

export default SharpImageFixed
